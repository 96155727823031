/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'codemirror/lib/codemirror.css';
@import 'codemirror/addon/lint/lint.css';
@import 'codemirror/addon/hint/show-hint.css';

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&family=Do+Hyeon&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600;700;800;900&display=swap');

/*@import 'node_modules/quill/dist/quill.core.css';
@import 'node_modules/quill/dist/quill.snow.css'; //~*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'DM Sans', sans-serif;
}

html {
  scroll-behavior: smooth;
}

:root {
  --font-size: 16px;
  --padding: 1rem;
  --color-blue: #0E6AF4;
  --color-black: #030723;
  --bg-gray: #E6E1DB;
  --bg-fraud-gray: #E2E5E9;
  --border-gray: #D0D5DD;
  --border-section-blue: #74a4ee;
  --border-table-gray: #EAECF0;
  --bg-fond-gray: #8192A6;
  --bg-white: #FFF;
  --color-sidebar: #0E6AF4;
  --border-width-button: 1px;
  --default-border-radius: 8px;
  --cards-padding: 12px 20px;
  --sb-track-color: #ffffff;
  --sb-thumb-color: #2755FE;
  --sb-size: 10px;
}

.height-40 {
  height: 40px;
}

.card-separation-shadow {
  border-radius: 16px !important;
  border: 1px solid #EAECF0;
  //1px solid #EAECF0
}

.global-sizing {
  padding: 25px 10px 0 30px;
}

.separator-border {
  border-radius: 16px;
  border: 1px solid #EAECF0;
}

.fx-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.custom-card {
  border-radius: 8px;
  background-color: #fff;
  padding-block: 0 15px;
  padding-inline: 0;

  .header {
    margin-block: 0 8px;
  }

  .inner-content {

  }

  .body {
    display: flex;
    gap: 8px;
    flex-direction: column;
  }

}

.header-content {
  display: flex;
  gap: 5px;
  padding-bottom: 8px;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    gap: 8px;

    .title {
      font-family: 'Poppins', sans-serif;
      font-size: 1.3rem;
      color: rgb(41, 45, 50);
      font-weight: 600;
      line-height: 40px;
    }

    .tag {
      background-color: #F7FAFF;
      color: #0070FF;
      font-size: .9em;
      font-weight: 500;
      border-radius: 5px;
      padding: 2px 10px;
    }
  }

  .description {
    font-family: 'Poppins', sans-serif;
    font-size: .85em;
    font-weight: 300;
    color: #667085;
  }
}

.scrollable::-webkit-scrollbar {
  width: var(--sb-size);
}

.scrollable::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 8px;
}

.scrollable::-webkit-scrollbar-thumb {
  background: var(--color-blue);
  border-radius: 8px;
  border: 3px solid #ffffff;
}

@supports not selector(::-webkit-scrollbar) {
  body {
    scrollbar-color: var(--color-blue)
    var(--sb-track-color);
  }
}
